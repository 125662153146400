<template>
  <div class="share-content" >

    <section class="top-selection">
      <div class="container">
        <div class="top-categories py-2">
          <div class="top-category">
            <router-link :to="{ name: 'ShareOutfit'}" class="list-btn" :class="{'active': $route.name === 'ShareOutfit'}">好友的穿搭</router-link>
          </div>
          <div class="top-category">
            <router-link :to="{ name: 'ShareCloths'}" class="list-btn" :class="{'active': $route.name === 'ShareCloths'}">好友的衣櫥</router-link>
          </div>
          <div class="top-category">
            <router-link :to="{ name: 'ShareMarketPlace'}" class="list-btn" :class="{'active': isShareMarketPlace}">寄售 / 捐贈</router-link>
          </div>
          <div class="top-category">
            <router-link :to="{ name: 'ShareRecycleMap'}" class="list-btn" :class="{'active': $route.name === 'ShareRecycleMap'}">回收箱地圖</router-link>
          </div>
          <div class="top-category" v-if="user.serial === 516">
            <router-link :to="{ name: 'Test'}" class="list-btn" :class="{'active': $route.name === 'Test'}">測試用</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="bottom-content" ref="router-content">
      <router-view :scroll-top="routerScrollTop" :router-view-height="routerViewHeight" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
  name: 'Share',
  data() {
    return {
      routerScrollTop: 0,
      routerViewHeight: 0,

      // showTest: false,
      testCount: 0,
    };
  },
  components: {
  },
  beforeDestroy() {
    this.$emit('busy', false);
  },
  computed: {
    ...mapState(['user', 'cloths', 'clothCategories']),
    isShareMarketPlace() {
      switch (this.$route.name) {
        case 'ShareMarketPlace':
        case 'MarketPlaceService':
        case 'MarketPlaceHistory':
        case 'MarketPlaceRewards':
          return true;
        default:
          return false;
      }
    },
  },
  watch: {
    // async $route() {
    //   // await this.refreshCloths();
    //   const routeName = this.$route.name;
    //   if (routeName !== 'ShareOutfit' && routeName !== 'ShareCloths' && routeName !== 'ShareMarketPlace' && routeName !== 'ShareRecycleMap') {
    //     this.$router.push({
    //       name: 'ShareCloths'
    //     });
    //   }
    // }
  },
  async mounted() {
    // const routeName = this.$route.name;
    // if (routeName !== 'ShareOutfit' && routeName !== 'ShareCloths' && routeName !== 'ShareMarketPlace' && routeName !== 'ShareRecycleMap') {
    //   this.$router.push({
    //     name: 'ShareCloths'
    //   });
    // }
    setTimeout(() => {
      this.$refs['router-content'].addEventListener('scroll', () => {
        this.routerScrollTop = this.$refs['router-content'].scrollTop;
      }, false);
      this.routerViewHeight = this.$refs['router-content'].clientHeight;
    }, 1000);
  },
  methods: {
    // testClicked() {
    //   this.testCount += 1;
    //   if (this.testCount >= 5) {
    //     this.showTest = true;
    //   }
    // }
  }
}
</script>

<style scoped>
  .share-content {
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .top-selection {
    flex: 0 0 auto;
  }

  .top-categories {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    text-align: center;
  }

  .top-category {
    display: inline-block;
  }

  .bottom-content {
    flex: 1 1;
    overflow-y: scroll;
  }

  .list-btn {
    display: block;
    border-radius: 999px;
    background-color: transparent;
    color: var(--text-dark);
    padding: .5rem .8rem;
    transition: color .2s ease, background-color .2s ease;
    margin-right: .5rem;
  }

  .list-btn:hover {
    text-decoration: none;
  }

  .list-btn.active {
    background-color: var(--text-dark);
    color: #fff;
    font-weight: 600;
  }
</style>
<style  lang="scss" scoped>
@import "src/assets/scss/basic";
.share-content {
  .list-btn {
    padding: .25rem .75rem;
  }
  .list-btn.active {
    background-color: $typography-interactive-primary;
  }
}
</style>
  